import { GatewayPasswordReset } from "@raidinlyon/front"
import { useNavigate, useSearchParams } from "react-router-dom";


export function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <GatewayPasswordReset
      image="/logo.png"
      token={searchParams.get('t') || ''}
      onPasswordReset={() => navigate('/home', { replace: true })}
    />
  )
}