import React from "react";
import { checkToken } from "./checkToken";
import { Navigate } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  redirect?: boolean
}

export type PublicRouteProps = Props;

export function PublicRoute(props: PublicRouteProps) {
  const { children, redirect } = { redirect: true, ...props };
  const isAuth = checkToken();

  return (
    <>
      {isAuth && redirect ? <Navigate to="/home" replace /> : children}
    </>
  );
}