import { GoogleProvider, DataProvider, useEvent as useEventProvider } from "@raidinlyon/front";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  children?: React.ReactNode
}

export type PrivateProviderProps = Props;

export function PrivateProvider(props: PrivateProviderProps) {
  const { children } = props;
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/signin', { replace: true });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <GoogleProvider token="AIzaSyD-2bJ3hFNYA25VoGcQKlQimM9qC3lD56c">
        <DataProvider
          baseurl="/api"
          url={window.location.origin}
          onLogout={handleLogout}
          opts={{
            secure: window.location.protocol === 'https:',
            path: "/api/ws",
            transports: ['websocket']
          }}
        >
          {children}
        </DataProvider>
      </GoogleProvider>
    </LocalizationProvider>
  )
}

export function useEvent() {
  const provider = useEventProvider();
  const { eventnumber } = useParams();
  return eventnumber ? provider.items.find(e => e.number === Number(eventnumber)) : undefined;
}

export function useRiddle() {
  const event = useEvent();
  const { number } = useParams();
  return event && number ? event.riddles.items.find(r => r.number === Number(number)) : undefined;
}