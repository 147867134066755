import { App, AppMenuGroupItem, AppMenuItem, MobileAppBar, MobileAppMenu } from "@raidinlyon/front";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';


export function MobileRoot() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('root');

  const handleNavigate = (path: string) => () => {
    navigate(path, { replace: true })
    setOpen(false);
  } 

  return (

    <App drawerOpen={open} drawerWidth={0} drawerClosedWidth={0}>
      <MobileAppBar>
        <IconButton onClick={() => setOpen(v => !v)}>
          <MenuIcon />
        </IconButton>
      </MobileAppBar>
      <MobileAppMenu onClose={() => setOpen(false)}>
        <AppMenuGroupItem>
          <AppMenuItem icon={<HomeIcon/>} label={t('labelHome')} onClick={handleNavigate('/home')}/>
          <AppMenuItem icon={<EventIcon/>} label={t('labelEvent')} onClick={handleNavigate('/event')}/>
        </AppMenuGroupItem>
        <AppMenuGroupItem>
          <AppMenuItem icon={<MenuIcon/>} label='test 2' />
        </AppMenuGroupItem>
      </MobileAppMenu>
      <Outlet />
    </App>
  )
}