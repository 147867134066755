import { BrowserView, MobileView } from "react-device-detect";
import { DesktopUsersSettings } from "@raidinlyon/front";

export function UserSettings() {

  return (
    <>
      <BrowserView renderWithFragment>
        <DesktopUsersSettings/>
      </BrowserView>
      <MobileView renderWithFragment>
        Mobile UserSettings
      </MobileView>
    </>
  )
}