import { DesktopEvent, Event, EventRiddlesItem, MobileEvent } from "@raidinlyon/front";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useEvent } from "../provider/PrivateProvider";

export function EventApp() {
  const event = useEvent();
  const navigate = useNavigate();

  const handleSelectEvent = (event: Event) => {
    navigate('/event/' +event.number.toString(), { replace: true });
  }

  const handleSelectRiddle = (riddle: EventRiddlesItem) => {
    navigate('riddle/' + riddle.number);
  }

  return (
    <>
      <BrowserView renderWithFragment>
        <DesktopEvent event={event}  onSelectEvent={handleSelectEvent} onSelectRiddle={handleSelectRiddle}/>
      </BrowserView>
      <MobileView renderWithFragment>
        <MobileEvent event={event} onSelectEvent={handleSelectEvent} />
      </MobileView>
    </>
  )
}