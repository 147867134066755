import React from "react";
import { checkToken } from "./checkToken";
import { Navigate } from "react-router-dom";

interface Props {
  children?: React.ReactNode
}

export type PrivateRouteProps = Props;

export function PrivateRoute(props: PrivateRouteProps) {
  const { children } = props;
  const isAuth = checkToken();

  return (
    <>
      {!isAuth ? <Navigate to="/signin" replace /> : children}
    </>
  );
}