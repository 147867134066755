import { DesktopRiddleStatusSettings } from "@raidinlyon/front";
import { BrowserView, MobileView } from "react-device-detect";

export function RiddleStatusSettings() {
  
  return (
    <>
      <BrowserView renderWithFragment>
        <DesktopRiddleStatusSettings/>
      </BrowserView>
      <MobileView renderWithFragment>
        Mobile Riddle Menu
      </MobileView>
    </>
  )
}