
import './i18n'
import { BrowserView, MobileView } from 'react-device-detect'
import { DesktopRoot } from './DesktopRoot'
import { MobileRoot } from './MobileRoot'
import { PrivateProvider } from '../provider'
import { RiddleNavigation } from '../riddle/RiddleNavigation'

export function Root() {

  return (
    <PrivateProvider>
      <RiddleNavigation>
        <BrowserView renderWithFragment>
          <DesktopRoot />
        </BrowserView>
        <MobileView renderWithFragment>
          <MobileRoot />
        </MobileView>
      </RiddleNavigation>
    </PrivateProvider>
  )
}