import { createBrowserRouter, Navigate } from "react-router-dom"
import { PublicRoute } from "./PublicRoute"
import { PrivateRoute } from "./PrivateRoute"
import { Singin } from "./gateway/Singin"
import { Root } from "./root"
import { Home } from "./home/Home"
import { Settings } from "./settings"
import { EventApp } from "./event/EventApp"
import { RiddleMenu } from "./riddle/RiddleMenu"
import { RiddleApp } from "./riddle/RiddleApp"
import { UserSettings } from "./user/UserSettings"
import { EventSettings } from "./event/EventSettings"
import { ResetPassword } from "./gateway/ResetPassword"
import { ForgottenPassword } from "./gateway/ForgottenPassword"
import { RiddleStatusSettings } from "./riddle/RiddleStatusSettings"


const router = createBrowserRouter([
	{
		path: '/signin',
		element: <PublicRoute> <Singin /> </PublicRoute>
	},
	{
		path: '/password',
		element: <PublicRoute redirect={false} > <ResetPassword />  </PublicRoute>
	},
	{
		path: '/password/reset',
		element: <PublicRoute> <ForgottenPassword /> </PublicRoute>
	},
  {
    path: "/",
    element: <PrivateRoute> <Root/>  </PrivateRoute>,
    children: [
      {
        path: "",
        element: <Navigate to="/home" replace />
      },
      {
        path: "home",
        element: <Home/>
      },
      {
        path: "event/:eventnumber?",
        element: <EventApp/>
      },
      {
        path: "event/:eventnumber/riddle/:number",
				children: [
					{
						path: '',
						element: <RiddleMenu />
					},
					{
						path: ':screen/:screennumber?',
						element: <RiddleApp />
					}
				]
      }, 
			{
				path: 'settings',
				element: <Settings/>
			}, 
			{
				path: 'settings/users',
				element: <UserSettings/>
			}, 
			{
				path: 'settings/events/:eventnumber?',
				element: <EventSettings/>
			},
			{
				path: 'settings/status',
				element: <RiddleStatusSettings/>
			}
    ],
  },
]);

export default router;
