import { DesktopRiddle } from "@raidinlyon/front";
import { BrowserView, MobileView } from "react-device-detect";
import { useEvent, useRiddle } from "../provider/PrivateProvider";

export function RiddleApp() {
  const event = useEvent();
  const riddle = useRiddle();

  return (
    <>
      <BrowserView renderWithFragment>
        {event && riddle &&
          <DesktopRiddle id={riddle.id} />
        }
      </BrowserView>
      <MobileView renderWithFragment>
        Mobile Riddle
      </MobileView>
    </>
  )
}