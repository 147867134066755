import { GatewayLogin } from "@raidinlyon/front"
import { useNavigate } from "react-router-dom";

export function Singin() {
  const navigate = useNavigate();

  return (
    <GatewayLogin
      image="/logo.png"
      onLogin={() => navigate('/home', { replace: true })}
      onClickResetPassword={() => navigate('/password/reset', { replace: true })}
    />
  )
}