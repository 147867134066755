import { BrowserView, MobileView } from "react-device-detect";
import { DesktopHome, MobileHome, RiddleAggregateResult } from "@raidinlyon/front";
import { useNavigate } from "react-router-dom";

export function Home() {
  const navigate = useNavigate();

  const handleSelectRiddle = (riddle: RiddleAggregateResult) => {
    navigate(`/event/${riddle.eventNumber}/riddle/${riddle.number}`, { replace: true });
  }

  return (
    <>
      <BrowserView renderWithFragment>
        <DesktopHome onSelectRiddle={handleSelectRiddle} />
      </BrowserView>
      <MobileView renderWithFragment>
        <MobileHome onSelectRiddle={handleSelectRiddle} />
      </MobileView>
    </>
  )
}