import { DesktopEventSettings } from "@raidinlyon/front";
import { BrowserView, MobileView } from "react-device-detect";

export function EventSettings() {

  return (
    <>
      <BrowserView renderWithFragment>
        <DesktopEventSettings/>
      </BrowserView>
      <MobileView renderWithFragment>
        Mobile EventSettings
      </MobileView>
    </>
  )
}