import { RichContentViewerDisplayValue, RiddleNavigateOptions, RiddleNavigationProvider, RiddleScreenName, useLogger } from "@raidinlyon/front";
import { useEvent } from "../provider/PrivateProvider";
import { generatePath, matchRoutes, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React from "react";

interface Props {
  children?: React.ReactNode  
}

type RiddleNavigationProps = Props;

export function RiddleNavigation(props: RiddleNavigationProps) {
  const { children } = props;
  const log = useLogger('front:EventMain');
  const event = useEvent();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { number, screen, screennumber } = useParams();
  const display = React.useMemo(() => {
    const value = searchParams.get('display');
    if (!value) {
      return undefined;
    }
    return value as RichContentViewerDisplayValue;
  }, [searchParams]);

  const route = React.useMemo(() => {
    const result = matchRoutes([{
      path: '/event/:eventnumber/riddle/:number/:screen?/:screennumber?'
    }], location);
    return !result || result.length < 1 ? undefined : result[0];
  }, [location]);

  const handleNavigate = (options: RiddleNavigateOptions) => {
    if (route) {
      log('navigate', route.route.path, number, screen, screennumber, options);
      const path = generatePath(route.route.path, {
        eventnumber: route.params.eventnumber,
        number: options.number !== undefined ? options.number : route.params.number,
        screen: options.screen !== undefined ? options.screen : route.params.screen,
        screennumber: options.screennumber
      });
      
      log('path', path);

      navigate(path);

      setSearchParams(p => {
        if (options.edit) {
          p.set('edit', 'true');
        } else {
          p.delete('edit');
        }
        return p;
      });

      setSearchParams(p => {
        if (options.display) {
          p.set('display', options.display);
        } else {
          p.delete('display');
        }
        return p;
      });
    }
  }

  return (
    <RiddleNavigationProvider
      riddlenumbers={event?.riddles.items.map(r => r.number)}
      number={number !== undefined ? Number(number) : undefined}
      screen={screen as RiddleScreenName}
      screennumber={screennumber !== undefined ? Number(screennumber) : undefined}
      edit={searchParams.get('edit') === 'true'}
      display={display}
      onNavigate={handleNavigate}
    >
      {children}
    </RiddleNavigationProvider>
  )
}