import { i18n } from '@raidinlyon/front';


i18n.addResourceBundle('en', 'root', {
  labelHome: 'Home',
  labelEvent: 'Event',
  labelSettings: 'Settings'
});

i18n.addResourceBundle('fr', 'root', {
  labelHome: 'Home',
  labelEvent: 'Evénement',
  labelSettings: 'Paramètres'
});

export default i18n;