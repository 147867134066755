import { DesktopSettings } from "@raidinlyon/front";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";

export function Settings() {
  const navigate = useNavigate();

  const handleNavigate = (id: string) => {
    navigate('/settings/' + id, { replace: true })
  }

  return (
    <>
      <BrowserView renderWithFragment>
        <DesktopSettings onClick={handleNavigate}/>
      </BrowserView>
      <MobileView renderWithFragment>
        Mobile Settings
      </MobileView>
    </>
  )
}