import { Outlet, useNavigate, useParams } from "react-router-dom";
import { App, AppBar, AppMenu, AppMenuGroupItem, AppMenuItem, useRiddleNavigation } from "@raidinlyon/front";
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, IconButton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useEvent } from "../provider/PrivateProvider";

export function DesktopRoot() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const event = useEvent();
  const { number, screen } = useParams();
  const { t } = useTranslation('root');
  const rnavigate = useRiddleNavigation();

  return (
    <App drawerOpen={open} drawerWidth={200}>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: "calc(100% / 3)"}}
        >
          <IconButton color="inherit" onClick={() => setOpen(v => !v)}>
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          {event && event.picture && (
            <Avatar src={event.picture.path.original} sx={{ width: 32, height: 32, fontSize: 16, lineHeight: 1.6, mr: 1 }} />
          )}
          {event && event.name}
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "calc(100% / 3)"}}
        >
          {rnavigate.screenlabel && rnavigate.screenlabel}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ width: "calc(100% / 3)"}}
        >
        </Stack>
      </AppBar>
      <AppMenu sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}>
        <AppMenuGroupItem>
          <AppMenuItem icon={<HomeIcon />} label={t('labelHome')} onClick={() => navigate('/home', { replace: true })} />
          <AppMenuItem icon={<EventIcon />} label={t('labelEvent')} onClick={() => navigate('/event', { replace: true })} />
        </AppMenuGroupItem>
        {event && screen && (
          <AppMenuGroupItem sx={{ overflowY: "auto", overflowX: 'hidden' }}>
            {event?.riddles.items.map((option, index) => (
              <AppMenuItem
                key={option.id}
                icon={<Avatar sx={{ width: 24, height: 24, fontSize: 12, lineHeight: 1.6 }}>{option.numberLabel}</Avatar>}
                label={option.title}
                disabled={option.number === Number(number)}
                onClick={() => rnavigate.navigate({ number: option.number })} />
            ))}
          </AppMenuGroupItem>
        )}
        <AppMenuGroupItem>
          <AppMenuItem icon={<SettingsIcon />} label={t('labelSettings')} onClick={() => navigate('/settings', { replace: true })} />
        </AppMenuGroupItem>
      </AppMenu>
      <Outlet />
    </App>
  )
}