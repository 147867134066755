import { DesktopRiddleMenu } from "@raidinlyon/front";
import { BrowserView, MobileView } from "react-device-detect";
import { useRiddle } from "../provider/PrivateProvider";

export function RiddleMenu() {
  const riddle = useRiddle();
  
  return (
    <>
      <BrowserView renderWithFragment>
        {riddle && <DesktopRiddleMenu id={riddle.id} />}
      </BrowserView>
      <MobileView renderWithFragment>
        Mobile Riddle Menu
      </MobileView>
    </>
  )
}