import './App.css';
import './i18n';
import "dayjs/locale/fr";
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import router from './routes';
import React from 'react';
import { GatewayProvider, Logger, NotificationProvider, Theme } from '@raidinlyon/front';

function App() {

  React.useEffect(() => {
    let title = "RAIDinLyon"

    if (process.env.REACT_APP_PROD_HOSTNAME !== document.location.hostname) {
      title = "Test: " + title;
    }

    document.title = title;
  }, [])

  return (
    <Theme>
      <Logger>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <GatewayProvider baseurl='/api'>
            <NotificationProvider autoHideDuration={5000} anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }} >
              <RouterProvider router={router} />
            </NotificationProvider>
          </GatewayProvider>
        </LocalizationProvider>
      </Logger>
    </Theme>
  );
}

export default App;
